
/* Cart ============================================================================== */
.cart-msrp-totals {
    color: red;
    font-size: 12px !important;
    font-weight: bold;
    margin: 10px 10px 0;
    padding: 10px;
    text-align: right;
    text-transform: uppercase;
}

/* ======================================================================================= */
.as-placeholder {
    display: none;
}
