.promo-label {
    position: relative;
    margin-top: 5px;
    width: fit-content;
    z-index: 1;

    &__link {
        background-color: $sherpa-blue;
        box-shadow: 5px 2px 13px rgba(138, 148, 159, 0.406766);
        border-radius: 24px;
        font-size: 12px;
        font-weight: $font-weight-bold;
        color: #fff;
        padding: 5px 30px;
        min-height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;

        &:hover,
        &:focus {
            color: #fff;
        }

        &:focus {
            outline-color: transparent;
        }
    }
}

.promo-badge {
    $b: &;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.10);
    border-radius: 8px;
    background-color: #fff;
    padding: 22px 10px 20px;
    display: flex;
    align-items: center;
    max-width: 245px;
    margin: 30px auto 10px;

    @media #{$media-to-sm} {
       margin-top: 20px;
    }

    &__icon {
        width: 36px;
        height: 36px;
        border-radius: 50%;
        background-color: #fff;
        box-shadow: 0px 2px 15px rgba(183, 189, 196, 0.570852);
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 10px;
        flex-shrink: 0;
    }

    #{$b}__text {
        color: $sherpa-blue;
        font-size: 14px;
        line-height: 15px;
        font-weight: $font-weight-bold;
        text-align: center;
        margin-bottom: 0;
        cursor: pointer;

        &:hover {
            color: $sherpa-blue;
        }

        &:focus {
            outline-color: transparent;
        }
    }
}
