.popular-categories {
    $b: &;
    padding: 0 65px 40px;
    position: relative;

    @media #{$media-sm} {
        padding: 0 35px 40px;
    }

    @media #{$media-xs} {
        padding: 0 0 30px 0;
    }

    &__title {
        font-size: 22px;
        color: $sherpa-blue;
        margin-bottom: 45px;
        text-align: center;

        @media #{$media-xs} {
            font-size: 20px;
        }

        @media #{$media-to-sm} {
            text-align: left;
        }
    }

    &__wrapper {
        margin-top: 10px;
    }

    #{$b}__list {
        margin: 0;
        display: flex;

        @media #{$media-sm-up} {
            overflow: hidden;
        }
    }

    #{$b}__item {
        list-style-type: none;
        margin-bottom: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        min-width: 16.66%;

        @media #{$media-sm} {
            min-width: 25%;
        }

        @media #{$media-xs} {
            min-width: 130px;
            max-width: 135px;
        }
    }

    &__img {
        border-radius: 12px;
        width: 101px;
        height: 101px;
        object-fit: cover;
        margin-bottom: 30px;
    }

    &__link {
        color: #253D4E;
        font-size: 18px;
        font-weight: $font-weight-bold;
        padding: 0 10px;
    }

    .glider-track {
        align-items: flex-start;

        .popular-categories {
            &__item {
                min-width: unset;
            }
        }
    }

    .glider-slide {
        @media #{$media-xs} {
            min-width: 120px;
        }
    }


    // todo refactor after all widgets - add global styles to all slider buttons
    .slider-btn {
        position: absolute;
        top: 148px;
        color: $sherpa-blue;
        visibility: hidden;
        opacity: 1;

        @media #{$media-xs} {
            display: none;
        }

        &--right {
            right: 15px;
        }

        &--left {
            left: 15px;
            transform: rotate(180deg);
        }
    }
}
