.c-product-categories {
   display: flex;
   padding-bottom: 40px;

   @media #{$media-xs} {
       padding-bottom: 30px;
       flex-direction: column;
       margin-right: -15px;
   }

    &__menu {
        width: 238px;
        min-width: 238px;
        background-color: $blue-dianne;
        border: 1px solid #EBEBEB;
        border-radius: 12px;
        color: #fff;
        padding: 24px 21px 18px;
        margin-right: 40px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        @media #{$media-sm} {
            width: 30%;
            min-width: 30%;
            margin-right: 0;
        }

        @media #{$media-xs} {
            min-width: unset;
            width: calc(100% - 15px);
            margin-right: 0;
            margin-bottom: 15px;
        }

        &.show-all {
            .c-product-categories {
                &__arrow {
                    &:after {
                        border-width: 2px 0 0 2px;
                        transform:  translateY(50%) rotate(45deg);
                    }
                }

                &__header {
                    &:nth-child(n+5) {
                        @media #{$media-xs} {
                            display: block;
                        }
                    }
                }
            }
        }

        &.hide-arrow {
            .c-product-categories {
                &__arrow {
                    display: none;
                }
            }
        }
    }

    &__main-header {
        font-size: 16px;
        font-weight: $font-weight-medium;

        &.is-active {
            font-weight: $font-weight-bold;
        }
    }

    &__header {
        font-weight: 500;
        &.is-active {
            text-decoration: underline;
        }

        &:nth-child(n+5) {
            @media #{$media-xs} {
                display: none;
            }
        }
    }

    &__header,
    &__main-header {
        margin-bottom: 12px;
        cursor: pointer;

        &:hover {
            text-decoration: underline;
        }
    }

    // todo refactor more links after all widgets merge
    &__more {
        text-align: right;

        @media #{$media-xs} {
            display: none;
        }

        a {
            color: #fff;
            font-size: 16px;
            text-decoration: underline;
            font-weight: $font-weight-medium;

            .icons {
                display: inline-flex;
            }

            svg {
                fill: #fff;

                path {
                    fill: #fff;
                }
            }
        }
    }

    &__arrow {
        width: 100%;
        height: 31px;
        display: block;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        display: none;

        @media #{$media-xs} {
            display: flex;
        }

        &:after {
            content: '';
            border-color: #fff;
            border-style: solid;
            border-width: 0 2px 2px 0;
            cursor: pointer;
            display: block;
            height: 12px;
            transform: rotate(45deg);
            width: 12px;
            z-index: 1;
            position: relative;
            top: -2px;
        }
    }
}

.c-product-category {
    width: 862px;
    position: relative;

    @media #{$media-md} {
        width: 662px;
    }

    @media #{$media-sm} {
        width: 70%;
        padding-left: 20px;
    }

    @media #{$media-xs} {
        width: 100%;
    }

    &__title {
        font-size: 22px;
        color: $sherpa-blue;
        margin: 0 0 10px;

        @media #{$media-sm} {
           font-size: 20px;
           padding-right: 180px;
        }
    }

    &__items {
        position: relative;
        visibility: hidden;
        z-index: -99;
        height: 0;
        margin: 0;
        padding-top: 0;

        &.is-active {
            visibility: visible;
            z-index: 1;
            height: auto;
            padding-top: 4px;

            .c-product-category__btn {
                display: flex;

                @media #{$media-xs} {
                   display: none;
                }
            }
        }
    }

    &__item {
        @media #{$media-xs} {
           max-width: 240px;
        }
    }

    &__more {
        position: absolute;
        top: 1px;
        right: 28px;
        font-size: 16px;

        @media #{$media-sm} {
            top: 7px;
        }

        @media #{$media-xs} {
           position: relative;
           display: block;
           text-align: right;
           right: 15px;
           margin-top: 30px;
           font-size: 12px;
        }

        a {
            color: $sherpa-blue;
            font-weight: $font-weight-bold;
        }

        .icons {
            display: inline-flex;

            svg {
                fill: $sherpa-blue;
            }
        }
    }

    &__wrapper {
        padding: 0 60px;

        @media #{$media-sm} {
            padding: 0 35px;
        }

        @media #{$media-xs} {
            padding: 0;
        }
    }

    &__slider {
        display: flex;

        @media #{$media-xs} {
            margin-left: -2px;
        }
    }

    &__btn {
        position: absolute;
        top: 56%;
        opacity: 1;
        display: none;

        @media #{$media-to-sm} {
            display: none;
        }

        &--left {
            left: 30px;
            transform: rotate(180deg);

            @media #{$media-sm} {
                left: 10px;
            }
        }

        &--right {
            right: 30px;

            @media #{$media-sm} {
                right: 10px;
            }
        }
    }

    .product-item {
        padding-bottom: 0;

        @media #{$media-xs} {
           margin-left: 0;
           margin-right: 0;
        }
    }
}

/* NEW HOMEPAGE - styles to remove or edit after all widgets merge
------------------------------------------------------------------------ */
.std,
.homepage {
    #section-chat,
    .special-offer-slider,
    .c-product-categories,
    .article-section {
        padding-right: 0;
        padding-left: 0;
        max-width: 1140px;

        @media #{$media-xs} {
            padding-right: 0;
            padding-left: 0;
        }
    }

    .cms-adbanner {
        margin-bottom: 30px;
    }
}
