.neuca-rules {
    margin-top: 20px;

    h2 {
        margin: 45px auto;
    }

    h3 span {
        display: block;
        margin-bottom: 5px;
    }

    ul,
    ol {
        a {
            color: #35569f;
            border-bottom: 1px solid rgba(53, 86, 159, 0.35);
            padding-bottom: 2px;
        }

        li {
            margin-bottom: 9px;
            line-height: 24px;
        }
    }

    ul {
        list-style: disc outside;
        padding-left: 20px;
        margin-bottom: 20px;
        margin-top: 20px;

        li {
            margin-bottom: 5px;
            list-style-type: lower-alpha;
        }
    }

    ol {
        list-style: decimal outside;
        counter-reset: item;
        padding-left: 0;
        margin-top: 30px;
        margin-bottom: 45px;
        text-align: justify;

        > li {
            display: table;
            counter-increment: item;

            &:before {
                content: counters(item, '.') '. ';
                display: table-cell;
                padding-right: 5px;
            }

            ol {
                padding-left: 20px;
                margin-top: 10px;
                margin-bottom: 0;

                li {
                    > ol {
                        padding-left: 0;
                    }
                }
            }

            ul {
                margin-bottom: 0;
            }
        }
    }

    li ol > li:before {
        content: counters(item, '.') ' ';
    }
}
