/* CMS MORE CONTENT LINK
-------------------------------------------------------------- */
.cms-more {
    background: $body-bg;
    box-shadow: 5px 5px 10px #c1bbb1;
    border-radius: 8px;
    padding: 24px 0;
    text-align: center;

    &__label {
        margin-bottom: 30px;
        font-weight: 500;

        @media #{$media-lg} {
            display: inline-block;
            margin-bottom: 0;
            margin-right: 10px;
            vertical-align: middle;
        }
    }

    // @extend global/components/buttons.scss
    .btn {
        margin: 10px 10px 10px 0;
        padding: 9px 20px;
        min-width: 0;
        font-size: 16px;
        font-weight: $font-weight-bold;
        text-transform: none;
        border-radius: 32px;

        @media #{$media-xs} {
            margin-bottom: 15px;
            padding-top: 10px;
            padding-bottom: 10px;
            min-height: 40px;
            font-size: inherit;
        }
    }
}
