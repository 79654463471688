/* TEXT TRUNCATE/OVERFLOW
-------------------------------------------------------------- */
// Overflow text by elipsis to max-width
// ---
// @param [string] $max-width
// ---
@mixin n-truncate($max-width) {
    display: inline-block;
    max-width: $max-width;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

/* HOVER VARIANT
-------------------------------------------------------------- */
// Return hover variant
// ---
// @param [string] $color: base color
// ---
@mixin n-hover($color: red, $properties: background-color) {
    @each $property in $properties {
        #{$property}: palette($color);
    }

    &:hover,
    &:focus {
        @each $property in $properties {
            #{$property}: palette($color, hover);
        }
    }
}

/* TRANSITION DEFAULT
-------------------------------------------------------------- */
// Get default transition
// ---
// @param [string] $option: css property to animate
// @param [string] $time:   transition time
// @param [string] $func:   animate function
// ---
@mixin n-transition(
    $option: background-color,
    $time: $n-transition-time,
    $func: $n-transition-func
) {
    @include transition($option $time $func);
}

/* ABS CENTER
-------------------------------------------------------------- */
// Absolute center positioning
// ---
// @param [string] $direction: vertical, horizontal, all
// @param [string] $height: auto
// @param [string] $width: auto
// ---
@mixin n-abs($direction: vertical, $height: auto, $width: auto) {
    position: absolute;

    @if ($direction == all or $direction == vertical) {
        top: 0;
        bottom: 0;
    }

    @if ($direction == all or $direction == horizontal) {
        left: 0;
        right: 0;
    }

    width: $width;
    height: $height;
    margin: auto;
}

/* TRANSFORM CENTER
-------------------------------------------------------------- */
// Centering by translate
// ---
// @param [string] $position: absolute, relative
// ---
@mixin n-center($position: relative) {
    position: $position;
    top: 50%;
    transform: translateY(-50%);
}

/* CSS CIRCLE
-------------------------------------------------------------- */
// Pure css circle element
// ---
// @param [string] $size: height/width
// @param [string] $color: background color
// ---
@mixin n-circle($size: 50px, $color: palette(red)) {
    width: $size;
    height: $size;
    border-radius: 50%;
    background-color: $color;
}

/* LOGOTYPE BACKGROUND
-------------------------------------------------------------- */
// Logotype in background
// ---
// @param [string] $size: height/width
// @param [string] $position: absolute position
// ---
@mixin n-logotype-bg($size: 205px, $opacity: 0.1) {
    position: absolute;
    width: $size;
    height: $size;
    background: url(../images/logotype.svg) no-repeat;
    background-size: contain;
    opacity: $opacity;

    @include n-theme() {
        background-image: url(theme(logotype));
    }
}

/* ICON
-------------------------------------------------------------- */
// Generate icon values
//@mixin n-icon() {
//    /* use !important to prevent issues with browser extensions that change fonts */
//    font-family: 'icons' !important;
//    speak: none;
//    font-style: normal;
//    font-weight: normal;
//    font-variant: normal;
//    text-transform: none;
//    line-height: 1;
//    vertical-align: middle;
//
//    /* Better Font Rendering =========== */
//    -webkit-font-smoothing: antialiased;
//    -moz-osx-font-smoothing: grayscale;
//}

/* BADGE / SIZE
-------------------------------------------------------------- */
// Calc size for badge component
// ---
// @param [string] $size: height/width
// @param [string] $icon-size: icon size
// ---
@mixin n-badge-size($size, $icon-size: null) {
    width: $size;
    height: $size;

    @if $icon-size != null {
        .icon {
            width: $size - 15;
            height: $size - 15;
        }
    }
}

/* BADGE / COLOR VARIANT
-------------------------------------------------------------- */
// Background and border for badge
// ---
// @param [string] $bg: background color
// @param [string] $border: border color
// @param [string] $hover: hover color
// @param [string] $color: text color
// ---
@mixin n-badge-color($bg, $border: $bg, $hover: $bg, $color: null) {
    background-color: $bg;
    border-color: $border;

    &:hover {
        background-color: $hover;
        border-color: $hover;
    }

    @if $color != null {
        i {
            color: $color;
        }
    }
}

/* BUTTONS / RESET
-------------------------------------------------------------- */
// Reset core styles for button
@mixin n-button-reset() {
    padding: 0;
    min-height: 0;
    min-width: 0;
    cursor: pointer;
    background: transparent;
    border: 0;
    border-radius: 0;
    color: inherit;

    @include n-theme() {
        background: none;
        //color: inherit;

        &:hover,
        &:active,
        &:focus {
            background-color: inherit;
            border-color: inherit;
        }
    }

    &:hover,
    &:active,
    &:focus {
        background: none;
        color: inherit;
    }
}

/* COLORS / VARIANT
-------------------------------------------------------------- */
// Color for text and border
@mixin n-color-variant($color: palette(blue)) {
    border-bottom-color: $color;

    &.label-tag,
    .label-tag,
    i {
        color: $color;
    }
}

/* COLORS / THEME VARIANT
-------------------------------------------------------------- */
// Themify alias
@mixin n-theme($attr: null, $themes: $themes) {
    @include themify($attr, $themes) {
        @content;
    }
}
