/* RATINGS STARS
-------------------------------------------------------------- */
.rate-box {
    display: inline-block;
    position: relative;
    width: 101px;
    height: 15px;
    font-size: 0;
    line-height: 0;
    background: url(../images/content/product/star_gray.png) 0 0 repeat-x;
    background-size: auto 15px;
    text-indent: -999em;
    overflow: hidden;

    > span {
        display: block;
        position: absolute;
        height: 100%;

        &:after {
            content: '';
            display: block;
            width: 100%;
            height: 100%;
            background: url(../images/content/product/star_red.png) 0 0 repeat-x;
            background-size: auto 15px;
            position: absolute;
        }
    }
}
