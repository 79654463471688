.banner-404 {
    overflow: hidden;

    .product-list {
        display: flex;
        @media #{$media-to-md} {
            min-width: 100%;
            display: block;
        }

        &__banner {
            position: relative;
            float: left;
            min-width: 138px;
            margin-right: 5px;
            @media #{$media-to-md} {
                float: none;
                width: 100%;
                height: 85px;
                margin-right: 0px;
                margin-bottom: 20px;
            }

            img {
                display: block;
                width: 100%;
                height: 100%;
            }

            .banner_adds {
                position: absolute;
                width: 100%;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                text-align: center;

                &__txt {
                    @media #{$media-to-md} {
                        height: 35px;
                    }
                    height: 50px;
                    text-align: center;
                    color: $sherpa-blue;
                    font-size: 19px;
                    font-weight: 600;
                }

                &__btn {
                    min-width: 80px;
                    color: $sherpa-blue;
                    font-size: 21px;
                    font-weight: 500;
                    border: 1px solid $sherpa-blue;
                    padding: 0px 15px;
                    min-height: 40px;

                    &:hover,
                    &:focus,
                    &:active {
                        background-color: transparent;
                    }
                }
            }
        }

        &__content {
            min-width: 996px;
            @media #{$media-to-md} {
                min-width: 100%;
            }
        }

        .page-subtitle {
            height: 50px;

            h2 {
                float: left;
                text-align: left;
                color: #1A6C7A;
                font-size: 22px;
                font-weight: 600;
            }

            a {
                float: right;
                font-size: 19px;
                font-weight: 500;
                color: #1A6C7A;
                cursor: pointer;

                @media #{$media-to-md} {
                    display: none;
                }
            }
        }

        .prod-box {
            .price {
                font-size: 18px;
            }
        }
    }
}
