//carousel
.rich_content-widget {
    max-width : 936px;
    margin: 0 auto;
    position: relative;

    @media (max-width: 768px) {
        max-width : 100%;
        padding: 0 20px;
    }
    .product-list {
        position: initial;
        &.product-slider {
            max-width : 100%;
            padding: 0px 83px;

            @media (max-width: 768px) {
                padding: 0px 63px;
            }

            position: initial;

            .cart-crosssell {
                background-color: $white;
            }
            .product-list__content {
                position: relative;
                max-width : 725px;
                margin: 0 auto;


                .product-list__wrapper {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding-right: 5px;

                    .slider-main__dots.glider-dots {
                        display: none;
                    }

                    .product-collection__btn {
                        &--left {
                            left: -63px;
                        }

                        &--right {
                            right: -63px;
                        }

                        &.btn--clear {
                            background: none;
                            border: none;
                            cursor: pointer;

                            svg {
                                width: 16px;
                                height: 16px;
                            }
                        }
                    }

                    .product-list__items {
                        display: flex;
                        box-sizing: border-box;

                        .product-list__item {
                            margin: 10px 8px 20px 8px;
                            max-width: 231px;
                            border: 1px solid #CEE7D8;
                            padding: 0 20px;
                            border-radius: 12px;
                            overflow: hidden;
                            height: 393px;

                            @media (max-width: 768px) {
                                margin: 10px 10px 20px 10px;
                            }
                        }

                    }
                }
            }
        }
    }
    @media (max-width: 540px) {
        max-width: initial;
        width : 280px;
        padding: 0;
        .product-list {
            position: initial;

            &.product-slider {
                max-width: 100%;
                padding: 0;
                position: initial;

                .product-list__content {
                    min-width : 320px;

                    .product-list__wrapper {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;

                        .product-list__items {
                            margin-bottom: 0;
                        }
                        .slider-main__dots.glider-dots {
                            display: block;
                        }

                        .product-collection__btn {
                            &--left {
                                display: none;
                            }

                            &--right {
                                display: none;
                            }

                            &.btn--clear {
                            }
                        }
                    }
                }
            }
        }

    }
}
// Items
.rich_content-widget {
    .product-list {
        .product-list__content {
            .product-list__item {
                height: 394px;
                .prod-box{
                    .prod-box__img{
                        padding: 0;
                        width: 116px;
                        height: 128px;
                        margin: 16px auto 24px auto;
                        min-height: initial;
                        .prod-box__labels{
                            top: -6px;
                            right: -54px;
                            justify-self: flex-end;

                        }
                    }
                    .prod-box__title{
                        font-weight: 600;
                        font-size: 14px;
                        line-height: 18px;
                        padding: 0;
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 3;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        height: 56px;

                    }
                    form {
                        padding-top: 12px;
                        padding-bottom: 20px;
                        padding-left: 0;
                        padding-right: 0;
                        span.price{
                            font-weight: $font-weight-medium;
                            font-size: 20px;
                            line-height: 28px;
                        }
                        .prod-box__btn{
                            margin-top: 12px;
                            font-weight: $font-weight-bold;
                            font-size: 16px;
                            line-height: 22px;
                            width: calc(100% + 10px);
                            min-width: 187px;
                            padding-right: 38px;
                            padding-top: 9px;
                            padding-bottom: 9px;
                            padding-left: 10px;
                            margin-left: -5px;
                            margin-right: -5px;
                        }
                    }
                    .price-additional-info{
                        font-weight: $font-weight-light;
                        font-size: 10px;
                        line-height: 12px;
                        padding-bottom: 24px;
                    }
                }
                .prod-box__btn:after {
                    right: 8px;
                }
            }
        }
    }
}

.widget_product_checkout {
    .product-shop__cart {
        height: 232px;
        width: 200px;
        padding: 22px 18px;

    }
    .product-box__img {
        width: 126px;
        height: 112px;
        margin: 0 auto;
    }
    .product-box__price {
        font-size: 20px;
        line-height: 28px;
        font-weight: 400;
        color: $blue-dianne;
        text-align: center;
    }
    .product-box {

        &__action {
            padding-top: 10px;
            width: 158px;
            margin: 0 auto;
            display: block;

            @media #{$media-to-sm} {
                padding-right: 5px;
            }

            form {
                display: flex;
                width: 100%;
                justify-content: space-between;
            }

            .btn {
                min-width: initial;
                min-height: initial;
                border: none;
            }

            .btn-cart {
                width: 40px;
                height: 37px;
                border-radius: 50%;
                display: flex;
                align-items: center;
                padding: 6px;
                background: $sherpa-blue;
                margin: 1px;

                &:focus{
                    color: initial;
                    background-color: initial;
                }
                &:hover {
                    color: $white;
                    background-color: $btn-default-hover;
                }

                .icon-cart2 {
                    margin-right: 2px;
                }
                svg {
                    fill: $white;
                }

                &:hover {
                    .icon {
                        @media #{$media-to-sm} {
                            svg {
                                filter: $svg-white;
                            }
                        }
                    }
                }

                @media #{$media-to-sm} {
                    background: white;
                }

                .icon {
                    @media #{$media-to-sm} {
                        display: flex;
                        align-items: center;
                        margin-left: 0;

                        svg {
                            filter: $svg-main-green;
                        }
                    }
                }
            }

            .qty-form--gray {
                min-width: 105px;
                height: 40px;
                width: 40px;
                background: $gray-light;
                border: 1px solid $gray-light;
                border-radius: 32px;
                color: $blue-dianne;
                margin-bottom: 0px;
                display: flex;

                @media #{$media-to-sm} {
                    display: none;
                }

                input {
                    background: $gray-light;
                    color: $blue-dianne;
                    font-size: 20px;
                    font-weight: 500;
                    line-height: 28px;
                    width: 30px;
                    padding: 6px 0;
                    text-align: center;
                    height: 40px;
                    border: none;
                    font-style: initial;
                }
            }

            .qty-form--gray .qty-form--btn {
                width: 40px;
                height: 37px;
                border-radius: 50%;
                font-size: 20px;
                font-weight: 500;
                line-height: 28px;
                color: $blue-dianne;


                &:focus{
                    color: initial;
                    background-color: initial;
                }
                &:hover {
                    color: $white;
                    background-color: $btn-default-hover;
                }

            }

            .qty-form--gray .qty-form__btn-up {
                box-shadow: 0px 4px 4px 0px rgba(185, 186, 197, 0.25);
                color: $sherpa-blue;
                background-color: $white;


                &:focus{
                    color: initial;
                    background-color: initial;
                }
                &:hover {
                    color: $white;
                    background-color: $btn-default-hover;
                }
            }
        }
    }
}
