/* NEWSLETTER FORM PAGE
-------------------------------------------------------------- */
.newsletter-page {
    position: relative;
    min-height: 790px;
    padding: 80px 80px 60px 80px;
    border-radius: 23px;
    box-shadow: 0 0 20px rgba(25, 25, 25, 0.05);
    background-image: url(../images/content/newsletter/bg.jpg);
    background-repeat: no-repeat;
    background-position: right top;
    overflow: hidden;

    @media #{$media-sm} {
        background-position: -190px top;
    }

    @media #{$media-xs} {
        padding: 45px 20px 0 20px;
        background-position: -570px top;
    }

    &:after {
        @media #{$media-md-up} {
            content: '';
            right: -435px;
            bottom: -435px;
            @include n-logotype-bg(870px, 0.05);
        }
    }

    /* WRAPPER
    -------------------------------------------------------------- */
    &__wrapper {
        position: relative;
        z-index: 1;

        @media #{$media-md-up} {
            width: 500px;
        }

        @media #{$media-xs} {
            padding-bottom: 70px;
        }
    }

    /* TITLE
    -------------------------------------------------------------- */
    &__title,
    &__subtitle {
        font-size: 30px;
        color: palette(blue);

        @include n-theme() {
            color: theme(color-primary);
        }

        @media #{$media-to-sm} {
            text-align: center;
        }
    }

    &__title {
        margin-bottom: 5px;
        font-weight: $font-weight-xbold;

        @media #{$media-xs} {
            font-size: 24px;
        }
    }

    &__subtitle {
        color: palette(blue, light);
        font-weight: $font-weight-light;

        @include n-theme() {
            color: theme(color-primary);
        }

        @media #{$media-xs} {
            font-size: 19px;
        }
    }

    /* FORM
    -------------------------------------------------------------- */
    &__form {
        margin-top: 35px;
    }

    &__field {
        margin-bottom: 40px;

        input {
            background-color: rgba(255, 255, 255, 0.35);

            @media #{$media-to-sm} {
                background-color: rgba(255, 255, 255, 0.7);
            }
        }

        .field-row {
            position: relative;
        }

        .tooltip {
            width: 200px;
        }

        .tooltip-icon {
            position: absolute;
            top: 0;
            bottom: 0;
            right: 18px;
            margin: auto;

            @media #{$media-xs} {
                top: -10px;
                right: -10px;
                bottom: auto;
            }
        }
    }

    &__agreements {
        margin-top: 55px;
        margin-bottom: 30px;
    }

    &__action {
        display: table;
        width: 100%;

        > div {
            @media #{$media-sm-up} {
                display: table-cell;
            }

            &:last-of-type {
                text-align: right;
            }
        }

        .required {
            font-size: 13px;
            text-transform: lowercase;

            @media #{$media-xs} {
                text-align: center;
            }

            em {
                color: palette(red);
            }
        }

        .cta {
            @media #{$media-xs} {
                display: block;
                position: absolute;
                bottom: 0;
                left: -20px;
                right: -20px;
            }
        }

        .btn {
            min-width: 285px;

            @media #{$media-xs} {
                width: 100%;
                border-radius: 23px;
                border-top-left-radius: 0;
                border-top-right-radius: 0;
            }
        }
    }

    /* ICON BADGES
    -------------------------------------------------------------- */
    &__benefits {
        font-size: 13px;

        p {
            display: table-cell;
            margin: 0;
            vertical-align: middle;
            line-height: 17px;
        }

        ul {
            display: table;
        }

        li {
            @media #{$media-sm-up} {
                display: table-cell;
                padding-right: 30px;
                vertical-align: top;
            }

            @media #{$media-xs} {
                margin-bottom: 10px;
            }

            &:first-of-type {
                @media #{$media-sm-up} {
                    width: 195px;
                }
            }

            &:nth-of-type(2) {
                @media #{$media-sm-up} {
                    width: 175px;
                }
            }

            &:last-of-type {
                @media #{$media-sm-up} {
                    padding-right: 0;

                    i {
                        position: relative;
                        top: 2px;
                    }
                }
            }
        }

        .input-group-btn {
            padding-right: 10px;
            vertical-align: top;
        }

        .badge-icon {
            @include n-badge-size(50px, 27px);
            border-color: palette(blue, light);
            color: #fff;

            @include n-theme() {
                border-color: theme(color-primary);
            }

            svg {
                &,
                & path {
                    fill: #fff;
                }
            }

            &:hover {
                background: palette(blue, light);
                border-color: palette(blue, light);

                @include n-theme() {
                    background-color: theme(color-primary);
                    border-color: theme(color-primary);
                }

                i {
                    color: #fff;
                }
            }
        }
    }
}

/* NEWSLETTER SIGNOUT PAGE
-------------------------------------------------------------- */

.newsletter-signout {
    background-image: url(../images/content/newsletter/signout.jpg);
    background-repeat: no-repeat;
    box-shadow: 0 0 20px rgba(25, 25, 25, 0.1);
    background-position: top right;

    @media #{$media-xs} {
        background-image: none;
    }

    i {
        font-size: 10px;
    }

    .btn {
        font-weight: normal;
        display: block;
        padding: 10px 30px;
        margin: 30px auto 30px 0;
    }

    &__restore {
        border-bottom: 1px dashed #afafaf;
        padding-bottom: 60px;
        margin-top: 30px;

        @media #{$media-xs} {
            text-align: center;
        }

        p {
            font-size: 17px;
        }

        a {
            color: $red;
            text-transform: uppercase;
            font-weight: 600;
            padding-top: 30px;
        }
    }

    &__message {
        padding-top: 50px;

        h2 {
            margin-bottom: 30px;
        }

        .input-group {
            padding: 5px 0;
            padding: 5px 0;
        }
    }
}

/* NEWSLETTER SIGNIN CONFIRMATION PAGE
-------------------------------------------------------------- */

.newsletter-confirmation {
    box-shadow: 0 0 20px rgba(25, 25, 25, 0.1);
    background-position: top right;
    min-height: 100%;

    h1 {
        margin-bottom: 30px;
        font-size: 30px;
        color: #35569f;
        font-weight: 700;
    }

    @media #{$media-sm-up} {
        padding: 90px 80px;
    }

    .btn {
        font-weight: normal;
        display: block;
        padding: 10px 30px;
        margin: 40px auto 40px 0;
        width: 200px;

        @media #{$media-xs} {
            margin: 40px auto;
        }

        i {
            font-size: 10px;
        }
    }

    &__wrapper {
        @media #{$media-md-up} {
            width: 700px;
        }
    }

    @media #{$media-xs} {
        text-align: center;
    }

    p {
        font-size: 17px;
        margin: 0;
    }

    .footer-why-apteline {
        padding-top: 30px;
        margin-bottom: 30px;
        background: none;
        border: none;
        height: auto;

        h3 {
            font-size: 26px;
            font-weight: 300;
            padding-bottom: 30px;

            @media #{$media-xs} {
                text-align: center;
            }
        }

        .footer-why-apteline {
            &__item {
                text-align: center;
            }

            &__text {
                text-align: center;
                padding-left: 0;
                padding-top: 15px;
            }
        }
    }
}

.cms-newsletter {
    .footer {
        .footer__newsletter {
            display: none;
        }
    }
}
