/* WISHLIST
-------------------------------------------------------------- */
.link-wishlist {
    &.wishlist-card {
        position: absolute;
        top: 10px;
        right: 10px;
        border-bottom: none;
        z-index: 2;
    }

    &.wishlist-added {
        .product-options__icon {
            background: $dark-green;
            path {
                stroke: $white;
            }
        }
        &:hover {
            .product-options__icon {
                background: $white;
                path {
                    stroke: none;
                    fill: $gray-dark;
                }
                @media (max-width: 480px) {
                    background: $dark-green;
                    path {
                        stroke: $white;
                    }
                }
            }
        }
    }
    &:hover {
        .product-options__icon {
            background: $dark-green;
            path {
                stroke: $white;
            }
            @media (max-width: 480px) {
                background: $white;
                path {
                    stroke: none;
                    fill: $gray-dark;
                }
            }
        }
    }

    &:disabled {
        background: transparent !important;
        border-color: transparent !important;
        opacity: 0.6;
    }

    .wishlist-icon {
        &__icon {
            stroke: black;
            stroke-width: 2;
            fill: transparent;
        }

        &__fill {
            transform: scale(0.01);
            transform-origin: 50% 50%;
            stroke-width: 0;
            transition: transform 0.25s ease;
            will-change: transform;
            fill: $sherpa-blue;
        }
    }

    &.wishlist-added {
        path {
            fill: $sherpa-blue;
        }
    }
}
