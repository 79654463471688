.pharmacy-points {
    display: flex;
    justify-content: space-evenly;

    &__sidebar {
        width: 100%;
        max-width: 270px;
    }
}

.pharmacy-point {
    width: 100%;
    font-style: normal;
    border: 1px solid $alto;
    padding: 10px;
    margin-bottom: 10px;
    font-size: 12px;
    font-weight: 500;

    > * {
        margin-bottom: 5px;
    }

    &__name {
        font-weight: 600;
        font-size: 14px;
        color: $blue-dianne;
    }

    &__text {
        color: $blue-dianne;

        &--bold {
            font-weight: bold;
        }
    }

    &__subheader,
    &__area,
    &__place
    {
        font-weight: 600;
        color: $blue-dianne;
    }

    &__location {
        > * {
            margin-right: 10px;
        }
    }

    &__phone-number {
        display: flex;
        align-items: baseline;
        margin-bottom: 0;
    }

    &__button {
        margin: 5px 5px 0 10px;
        background-color: $sherpa-blue;
        padding: 5px;
        font-weight: 500;
        font-size: 12px;
        line-height: 15px;
        color: #fff !important;
        text-transform: none;
        border-radius: 2px;
        min-width: unset !important;
        min-height: unset !important;
    }

    &__area,
    &__place {
        min-width: 100px;
    }

    p {
        margin: 0;
    }
}
