.sales-page-products {
    margin-top: 50px;
    flex-direction: column;
    align-items: center;
    max-width: 1000px;

    @media #{$media-to-sm} {
        max-width: 650px;
    }

    @media #{$media-xs} {
        max-width: 450px;
    }

    &__slider {
        margin-bottom: 60px;
        position: relative;
        width: 100%;
        text-align: center;
    }

    h2 {
        margin-bottom: 30px;
    }
}

.checkout-sale-index,
.checkout-sale-index1 {
    .frame-msg {
        margin-top: -63px;

        @media #{$media-to-sm} {
            margin-top: -35px;
        }

        @media #{$media-xs} {
            margin-top: -10px;
        }
    }

    .breadcrumbs__wrapper {
        &:after {
            display: none;
        }
    }

    .product-slider {
        &__wrapper {
            position: relative;
        }

        &__list {
            margin-bottom: 20px;
        }

        .product-collection__btn {
            @media #{$media-xs} {
                display: none;
            }
        }
    }

    .product-item {
        &__name {
            text-align: left;
        }

        &__img {
            display: flex;
            align-items: center;
            justify-content: center;

            @media #{$media-xs} {
                min-height: 236px;
            }
        }
    }

    .product-list__item {
        margin-bottom: 22px;

        @media #{$media-xs} {
            margin-bottom: 0;
        }
    }

    .header-main__middle {
        @media #{$media-to-sm} {
            padding-bottom: 0;
        }
    }
}
