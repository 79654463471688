/* NAVIGATION MAIN
-------------------------------------------------------------- */
.nav-main {
    $b: &;

    /* MAIN TOP LIST - GENERAL STORE
    -------------------------------------------------------------- */
    // hide all general children categories
    .level-children {
        @media #{$media-sm-up} {
            display: none;
        }
    }

    // hide selected general children categoriess
    &__primary[data-store='ogolna'] {
        li[class*='nav-'] {
            @media #{$media-xs} {
                display: none;
            }
        }
    }
}
