/**
* Bottom section with contact information
*/

/* FOOTER SECTION
-------------------------------------------------------------- */
.footer-section {
    padding: 70px 0;
    text-align: center;
    position: relative;

    @media #{$media-sm} {
        padding-bottom: 17px;
        padding-top: 77px;
    }

    @media #{$media-xs} {
        padding-top: 47px;
    }

    /* section title */
    &__title {
        margin-bottom: 60px;

        @media #{$media-xs} {
            margin-bottom: 45px;
        }

        h2 {
            margin: 0;
            font-size: 26px;

            @media #{$media-xs} {
                width: 85%;
                margin: auto;
            }
        }
    }

    &__item {
        p {
            display: inline-block;
            max-width: 220px;
        }

        .badge-icon {
            margin-bottom: 30px;
        }
    }

    &__item-safety {
        .text-wrapper {
            h5 {
                @media #{$media-sm} {
                    margin-top: 2px;
                    text-align: left;
                }
            }

            p {
                @media #{$media-sm} {
                    display: block;
                    max-width: none;
                    text-align: left;
                }
            }
        }

        .badge-icon {
            @media #{$media-sm} {
                float: left;
                margin-left: 7px;
                margin-right: 20px;
                margin-bottom: 60px;
            }
        }
    }

    &__bg {
        &:before,
        &:after {
            @include n-logotype-bg($size: 100%);
            content: '';
            background-size: 344px;
            background-repeat: no-repeat;

            @media #{$media-to-sm} {
                display: none;
            }
        }

        &:before {
            background-position: -174px 161px;
            left: 0;
            bottom: 0;

            @media #{$media-md} {
                background-position: -204px 161px;
            }
        }

        &:after {
            background-position: right -170px top -174px;
            right: 0;
            top: 0;

            @media #{$media-md} {
                background-position: right -200px top -174px;
            }
        }
    }
}

/* FOOTER SAFETY INFORMATION
-------------------------------------------------------------- */
.footer-safe {
    border-top: 1px solid rgba(0, 0, 0, 0.35);
}

/* FOOTER BOK/CONTACT INFORMATION
-------------------------------------------------------------- */
.footer-bok {
    position: relative;
    min-height: 460px;
    background: #f5f5f5;
    overflow: hidden;

    &:after,
    &:before {
        @include n-logotype-bg($size: 750px);
        content: '';
        position: absolute;
        top: 0;
        z-index: 0;
        background-position: -375px 150px;

        @media #{$media-to-sm} {
            display: none;
        }
    }

    &:after {
        left: 0;
    }

    &:before {
        right: 0;
        width: 750px;
        background-position: 375px -382px;
    }

    @media #{$media-to-sm} {
        padding-top: 80px;
    }

    @media #{$media-xs} {
        padding-top: 45px;
        padding-bottom: 10px;
    }

    &__list {
        margin: 0 6%;

        @media #{$media-to-sm} {
            margin: 0;
        }
    }

    &__item {
        @media #{$media-to-sm} {
            padding-right: 24px;
            padding-left: 24px;
        }

        @media #{$media-xs} {
            margin-bottom: 43px;
        }

        .icon-mail {
            font-size: 34px;
        }

        p {
            margin: 0 auto 10.5px auto;
        }

        p:first-of-type {
            font-size: 17px;
            display: block;
            font-weight: 600;
            line-height: 1.2;
        }
    }

    .container {
        position: relative;
        z-index: 1;

        @media #{$media-to-sm} {
            padding-right: 0;
            padding-left: 0;
        }
    }
}

/* FOOTER PHARMACY CONTACT
-------------------------------------------------------------- */
.pharmacy-contact {
    margin: 0 auto 50px;

    &__mobile {
        margin: 0 auto;
    }
}
