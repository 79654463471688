/* PROGRESS BAR
-------------------------------------------------------------- */
.progress-bar {
    overflow: hidden;
    height: 15px;
    background-color: $progress-bg;
    border-radius: $progress-border-radius;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);

    /* color bar fill */
    .bar {
        float: left;
        width: 0;
        height: 100%;
        text-align: center;
        background-color: $progress-bar-bg;
        border-radius: $progress-border-radius;
        box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
        @include n-transition(all, 0.4s, ease);
    }

    /* color variants */
    &--strong {
        .bar {
            background-color: palette(green);
        }
    }

    &--good {
        .bar {
            background-color: palette(green, light);
        }
    }

    &--easy {
        .bar {
            background-color: palette(red);
        }
    }

    &--weak {
        .bar {
            background-color: palette(orange);
        }
    }
}
