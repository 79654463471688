/* Special styles for product items with horizontal arrangement
--------------------------------------------------------------------- */
.products-list-special {

    .product-item {
        display: flex;
        max-width: 100%;
        padding: 5px 0 10px;

        @media #{$media-sm} {
            flex-direction: column;
            align-items: center;
        }

        &__img {
            min-height: unset;
            width: 110px;
            min-width: 110px;
            align-items: center;
            margin-right: 15px;

            img {
                margin: 0;
            }
        }

        &__data {
            max-width: 230px;
        }

        &__desc {
            padding: 0 0 5px 7px;
        }

        &__shop {
            max-width: 185px;
            margin-top: 5px;
        }

        &__btn {
            border-color: $blue-dianne;
            max-width: 185px;

            @media #{$media-sm-up} {
                min-width: 185px;
            }

            @media #{$media-md} {
                min-width: 165px;
            }

            .icon {
                margin-right: 12px;
            }
        }

        &__name {
            height: 75px;

            a {
                color: $black-pearl;
                font-size: 18px;
                line-height: 26px;
            }
        }

        &__labels {
            display: none;
        }

        .tooltip-max-price-info {
            margin: 8px 0 0;
            color: $blue-dianne;

            p {
                font-size: 11px;
            }
        }
    }
}

/* Styles for list of products - labels widget
-------------------------------------------------------------- */
.product-labels-list {
    .prod-box {
        &__title {
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-pack: end;
            -webkit-box-orient: vertical;
            overflow: hidden;
            height: 45px;
        }
    }
    $b: &;
    display: flex;
    padding: 0 15px 40px;

    @media #{$media-to-sm} {
       display: block;
    }

    @media #{$media-xs} {
        position: relative;
        margin-right: -15px;
        padding: 0 15px 30px;
    }

    &__wrapper {
        display: flex;
        width: 100%;
        justify-content: space-between;
    }

    &__title {
        font-size: 24px;
        font-weight: $font-weight-bold;
        position: relative;
        margin-bottom: 24px;
        padding-bottom: 13px;

        @media #{$media-md} {
            font-size: 22px;
        }

        @media #{$media-xs} {
            display: none;
        }

        &:after {
            content: '';
            display: block;
            width: 100px;
            height: 3px;
            background-color: #CC9716;
            position: absolute;
            bottom: 0;
        }

        &:nth-child(2),
        &--2 {
            &:after {
                background-color: #3D6CB2;
            }
        }

        &:nth-child(3),
        &--3 {
            &:after {
                background-color: #E60408;
            }
        }
    }

    &__headers {
        display: none;

        @media #{$media-xs} {
            display: flex;
            overflow-x: scroll;
            scrollbar-width: none;

            &:after {
                background: linear-gradient(270deg,#fff 26%,hsla(0,0%,100%,.17) 150.67%,hsla(0,0%,100%,.17) 160.67%,hsla(0,0%,100%,.17) 178%);
                content: "";
                display: block;
                height: 47px;
                position: absolute;
                right: 0;
                top: -18px;
                width: 30px;
            }
        }

        &::-webkit-scrollbar {
            @media #{$media-xs} {
                display: none;
            }
        }

        .product-labels-list__title {
            @media #{$media-xs} {
               display: inline-flex;
               font-size: 22px;
               flex-shrink: 0;
               margin-right: 22px;
               font-weight: $font-weight-medium;
               min-width: 100px;
            }

            &:last-of-type {
                @media #{$media-xs} {
                  margin-right: 30px;
                }
            }

            &.current-slide {
                font-size: 24px;
                font-weight: $font-weight-bold;
            }
        }
    }

    &__col {
        flex: 1;

        @media #{$media-xs-up} {
            max-width: 343px;
        }

        @media #{$media-md} {
           padding-right: 10px;
        }

        @media #{$media-to-sm} {
            padding-right: 0;
        }
    }

    &__more {
        display: flex;
        justify-content: flex-start;
        margin-top: 20px;
        font-size: 16px;

        @media #{$media-to-sm} {
            margin-top: 10px;
        }

        @media #{$media-xs} {
            font-size: 12px;
        }
    }

    &__dots {
        display: none;
    }

    .glider-track {
        column-gap: 15px;
    }
}

/* Styles for list of products - special offer widget
-------------------------------------------------------------- */
.products-list-columns {
    padding-bottom: 40px;

    @media #{$media-xs} {
        padding-bottom: 30px;
    }
    &__title {
        font-size: 22px;
        color: $sherpa-blue;
        font-weight: $font-weight-bold;
        text-align: center;
        margin-bottom: 43px;

        @media #{$media-to-sm} {
           margin-bottom: 35px;
        }

        @media #{$media-xs} {
           font-size: 20px;
           text-align: left;
        }
    }

    &__wrapper {
        display: flex;
        width: 100%;
        justify-content: space-between;
    }

    &__column {
        flfex: 1;

        @media #{$media-xs-up} {
            max-width: 343px;
        }

        @media #{$media-md} {
            max-width: 300px;
        }
    }
}

.products-list-columns,
.product-labels-list {

    .glider {
        @media #{$media-xs} {
            scrollbar-width: none;
        }

        &::-webkit-scrollbar {
            @media #{$media-xs} {
                display: none;
            }
        }
    }
}

.mobile-slider-dots {
    margin-top: 20px;

    .glider-dot {
        background: $sherpa-blue;
        border: 1px solid $sherpa-blue;
        border-radius: 100%;
        cursor: pointer;
        display: inline-block;
        height: 13px;
        width: 13px;
        margin: 0 18px;
        min-height: 13px;
        min-width: 13px;

        &.active {
            background: #31917F;
            border: 1px solid #31917F;
        }
    }
}

.more-link {
    color: $sea-blue;
    font-weight: $font-weight-bold;
    display: flex;
    align-items: center;

    .icons {
        display: inline-flex;
        position: relative;
        margin-left: 5px;
    }

    .icon {
        svg {
            path {
                fill: $sea-blue;
            }
        }
    }
}
