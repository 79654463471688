.banner-widget {
    $bannerWidget: &;
    padding-bottom: 40px;

    @media #{$media-xs} {
        padding-bottom: 30px;
    }

    &__header {
        text-align: center;

        @media #{$media-xs} {
           text-align: left;
        }
    }

    &__title {
        color: $sherpa-blue;
        font-weight: 600;
        font-size: 22px;
        line-height: 1.1;
        margin-bottom: 25px;

        @media #{$media-xs} {
            font-size: 20px;
            margin-bottom: 20px;
        }
    }

    &__slider {
        order: 0;
        flex: 1 1 auto;
        border-radius: 12px;
        padding: 10px 0 20px;
    }

    &__slider:not(.glider) {
        display: flex;
        justify-content: center;

        #{$bannerWidget}__item:nth-of-type(1n+4) {
            display: none;
        }
    }

    &__wrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    &__item {
        display: flex;
        justify-content: center;
        margin: 0 10px;

        @media #{$media-xs} {
            margin: 0 20px 0 0;
        }

        img {
            border-radius: 12px;
            box-shadow: 0px 2px 12px rgba(183, 189, 196, 0.5036);
        }
    }

    &__image {
        max-width: 100%;
        height: auto;

        &--small {
            display: none;
        }

        &--medium {
            display: block;
        }

        @media #{$media-to-sm} {
            &--small {
                display: block;
            }

            &--medium {
                display: none;
            }
        }
    }

    &__prev {
        order: -1;
        margin-right: 30px;
    }

    &__next {
        order: 1;
        margin-left: 30px;
    }

    &__prev,
    &__next {
        min-width: 32px !important;
        min-height: unset !important;
        border: none !important;

        &:hover,
        &:focus,
        &:active {
            background-color: transparent !important;
            border: none !important;
        }

        .icon {
            height: 22px;
        }
    }

    @media (max-width: 767px) {
        &__prev,
        &__next {
            display: none;
        }
    }
}
