/* ASIDE MAIN
-------------------------------------------------------------- */
aside {
    display: none;
    margin-bottom: $line-height-computed;

    @media #{$media-md-up} {
        display: block;
    }
}

.aside {
    &--left {
        //@media #{$media-lg} {
        //    padding-right: 50px;
        //}

        .category-cms {
            @media #{$media-to-sm} {
                display: none;
            }
        }
    }

    &__wrapper {
        &.is-active {
            box-shadow: 0 0 20px rgba(25, 25, 25, 0.15);
        }
    }

    &__title {
        display: none;
        padding-left: 24px;
        padding-right: 24px;
        line-height: 3.4;
        cursor: pointer;
        background-color: $gray-light;
        color: $gray-dark;
        transition: background 0.3s ease-out, color 0.3s ease-out;
        position: relative;

        @media #{$media-to-sm} {
            display: block;
        }

        .icon-filter {
            margin-right: 5px;
            font-size: 24px;
            color: $red;
        }

        .icon-arrow-top {
            display: none;
        }

        .icon-arrow-top,
        .icon-arrow-bottom {
            right: 26px;
            font-size: 11px;
            @include n-abs($height: 11px);
        }

        &.is-active {
            background-color: $blue;
            color: #ffffff;

            @include n-theme() {
                background-color: theme(color-primary);
            }

            .icon-arrow-bottom {
                display: none;
            }

            .icon-arrow-top {
                display: inline;
            }

            .icon-filter {
                color: #ffffff;
            }
        }
    }

    &__content {
        &:not(.collapse):not(.collapsing) {
            @media #{$media-to-sm} {
                display: none;
            }
        }

        .block-layered-nav {
            @media #{$media-to-sm} {
                margin-bottom: 0;
                padding-left: 24px;
                padding-right: 24px;
                clear: both;
            }

            &.aside__categories {
                @media #{$media-xs} {
                    padding-left: 0;
                    padding-right: 0;
                }

                .block-title {
                    @media #{$media-md-up} {
                        display: block;
                        padding-bottom: 0;
                    }

                    @media #{$media-xs} {
                        padding-left: 24px;
                        padding-right: 24px;
                    }
                }

                .block-content {
                    ol > li {
                        @media #{$media-xs} {
                            margin-left: 24px;
                            margin-right: 24px;
                        }

                        &.current {
                            @media #{$media-xs} {
                                width: 100%;
                                margin-left: 0;
                                margin-right: 0;
                            }
                        }
                    }
                }
            }

            .block-title {
                @media #{$media-to-sm} {
                    width: 100%;
                    border-bottom: 0;
                    padding-bottom: 0;
                    cursor: pointer;
                    color: $blue;
                    font-size: 15px;
                    line-height: 4;

                    @include n-theme() {
                        color: theme(color-primary);
                    }
                }
            }

            &:not(.aside__categories) {
                .block-content {
                    @media #{$media-sm} {
                        padding-left: 17px;
                        padding-top: 30px;
                    }
                }
            }

            &:not(:last-child):not(.is-active) {
                .block-title {
                    & > span {
                        display: block;
                        width: 100%;
                        border-bottom: 1px solid #afafaf;

                        @media #{$media-md-up} {
                            padding-bottom: 17px;
                        }
                    }
                }
            }

            &:last-child.is-active {
                @media #{$media-to-sm} {
                    padding-bottom: 35px;
                }

                .block-title {
                    @media #{$media-to-sm} {
                        border-bottom: 1px solid #afafaf;
                    }
                }
            }

            .icon-arrow-top {
                display: none;
            }

            .icon-arrow-top,
            .icon-arrow-bottom {
                font-size: 9px;
                margin-left: 11px;
            }

            &.is-active {
                .icon-arrow-top {
                    display: inline;
                }

                .icon-arrow-bottom {
                    display: none;
                }
            }
        }
    }
}
